<template>

  <div @click="onClick">
    <div class="inline field text-left">
      <vs-divider position="left">
        주소
      </vs-divider>
      <div class="m-4"> {{rowData.addr|| "-"}} </div>
      <vs-divider position="left">
        소속
      </vs-divider>
      <div class="m-4"> {{rowData.aff || "-"}} </div>
      <vs-divider position="left">
        병원 코드
      </vs-divider>
      <div class="m-4"> {{rowData.hospital_code || 0}} </div>

    </div>
  </div>
</template>

<script>
export default {
    name : "UserDetailRow",
    props: {
        rowData: {
            type: Object,
            required: true
        },
        rowIndex: {
            type: Number
        }
    },
    methods: {
        onClick (event) {
            console.log('my-detail-row: on-click', event.target)
        }
    }
}
</script>
<template>
  <div id="app" style="text-align: center;" data-app>
    <vs-navbar :collapse="false"  class="fixed-top menu-static"
               ref="parentSidebar"
               text-color="rgba(255,255,255,.6)"
               active-text-color="rgba(255,255,255,1)"
               :color="`#8CC152`" >

      <div slot="title">
        <vs-navbar-title>
          <vs-row vs-justify="center">
            <vs-col  vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6">
              <div style="margin-top: 6px">
            <!--    <img src="../../public/img/aib_logo.png" height="50%" width="50%"/>-->
              </div>
            </vs-col>
          </vs-row>
        </vs-navbar-title>
      </div>

      <vs-navbar-item index="0">
        <router-link to="/home">Home</router-link>
      </vs-navbar-item>

      <vs-navbar-item index="1" class="mr-10">
        <router-link to="/scoring" class="pr-10">Scoring Table</router-link>
      </vs-navbar-item>
      <vs-navbar-item index="7">
        <a href="/v1/logout">로그아웃</a>
      </vs-navbar-item>
      <vs-navbar-item index="2">
      </vs-navbar-item>
      <vs-spacer></vs-spacer>
      <vs-button color-text="rgb(255, 255, 255)" color="rgba(255, 255, 255, 0.3)" type="flat" ></vs-button>
    </vs-navbar>

    <div id="table-wrapper" class="ui container pt-20">
      <br>
      <br>

      <h2><strong>사용자 관리</strong></h2>

      <vuetable ref="vuetable"
                api-url="/v1/userList"
                :fields="fields"
                :sort-order="sortOrder"
                :css="css.table"
                pagination-path=""
                :detail-row-component="userDetailRow"
                :pagination-data="onPaginationData"
                @vuetable:cell-clicked="onCellClicked"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded"
      >
        <div slot="actions" slot-scope="props" class="center aligned" style="width: 110px; ">
          <div class="table-button-container">
            <button class="btn btn-warning btn-sm" @click="editRow(props)">
              <span class="glyphicon glyphicon-pencil"></span> Edit</button>&nbsp;&nbsp;
            <button class="btn btn-danger btn-sm" @click="deleteRow(props)">
              <span class="glyphicon glyphicon-trash"></span> Delete</button>&nbsp;&nbsp;
          </div>
        </div>

      </vuetable>
      <!--            <vuetable-pagination ref="pagination"
                                       :css="css.pagination"
                                       @vuetable-pagination:change-page="onChangePage"
                  ></vuetable-pagination>-->
    </div>


    <div class="centerx">

      <v-row justify="center">
        <v-dialog v-model="updatePopup" persistent max-width="600px">
          <v-card>
            <v-card-title class="btn-warning btn-sm" >
              <span class="headline font-weight-bold">사용자 수정</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field disabled label="아이디*" v-model="popupData.id" required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="이름" v-model="popupData.name" ></v-text-field>
                  </v-col>
<!--
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Password*" type="password" v-model="popupData.pw" required></v-text-field>
                  </v-col>
-->
                  <v-col cols="12">
                    <v-text-field label="Email" v-model="popupData.email" ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Password*" type="password" v-model="popupData.pw" required></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="주소" type="text" v-model="popupData.addr" required></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="소속" type="text" v-model="popupData.aff" required></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="병원코드" type="number" v-model="popupData.hospital_code" ></v-text-field>
                  </v-col>
<!--                  <v-col cols="12" sm="6">
                    <v-select
                        :items="['0-17', '18-29', '30-54', '54+']"
                        label="Age*"
                        required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                        :items="['Skiing', 'Ice hockey', 'Soccer', 'Basketball', 'Hockey', 'Reading', 'Writing', 'Coding', 'Basejump']"
                        label="Interests"
                        multiple
                    ></v-autocomplete>
                  </v-col>-->
                </v-row>
              </v-container>
<!--
              <small>*indicates required field</small>
-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn style="color:dodgerblue; font-weight:700" text @click="updatePopup = false">CLOSE</v-btn>
              <v-btn style="color:dodgerblue; font-weight:700" text @click="update">수정</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

    </div>
    <div @click="add()">
      <fab
          :position="'bottom-right'"
          :bg-color="'#0f4299'"
          :actions="fabActions"
          :main-icon="'add'"
      >
      </fab>
    </div>
    <v-row justify="center">
      <v-dialog v-model="addPopup" persistent max-width="600px">
        <v-card>
          <v-card-title style="background-color:#0076fc;" >
            <span class="headline font-weight-bold">사용자 추가</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field label="아이디*" v-model="addPopupData.id" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field label="이름" v-model="addPopupData.name" ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Email" v-model="addPopupData.email" ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Password*" type="password" v-model="addPopupData.pw" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="주소" type="text" v-model="addPopupData.addr" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="소속" type="text" v-model="addPopupData.aff" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="병원코드" type="number" v-model="addPopupData.hospital_code" ></v-text-field>
                </v-col>
                <!--                  <v-col cols="12" sm="6">
                                    <v-select
                                        :items="['0-17', '18-29', '30-54', '54+']"
                                        label="Age*"
                                        required
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-autocomplete
                                        :items="['Skiing', 'Ice hockey', 'Soccer', 'Basketball', 'Hockey', 'Reading', 'Writing', 'Coding', 'Basejump']"
                                        label="Interests"
                                        multiple
                                    ></v-autocomplete>
                                  </v-col>-->
              </v-row>
            </v-container>
            <!--
                          <small>*indicates required field</small>
            -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn style="color:dodgerblue; font-weight:700" text @click="addPopup = false">CLOSE</v-btn>
            <v-btn style="color:dodgerblue; font-weight:700" text @click="insert">추가</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="deleteDialog" persistent max-width="300">
        <v-card>
          <v-card-title class="headline" style="background-color: #ff1d1d; font-weight:700; color:#ffffff">사용자 삭제</v-card-title>
          <br>
          <v-card-text  style="font-weight:700; color:#000000">정말 {{ deleteId }}를 삭제 하시겠습니까? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="deleteDialog = false" style="color:dodgerblue; font-weight:700">취소</v-btn>
            <v-btn color="green darken-1" text @click="deleteAgree" style="color:dodgerblue; font-weight:700">삭제</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>

</template>

<script>

import Vuetable from 'vuetable-2'
import UserDetailRow from './UserDetailRow'
import axios from 'axios'
import fab from 'vue-fab'

export default {

  components : {
    Vuetable,
    fab,
  },

  name: "UserSetting",

  data() {
    return {
      userDetailRow : UserDetailRow,
      fabActions: [
/*
        {
          name: 'add',
          icon: 'add'
        }
*/
      ],
      value1:'',
      value2:'',
      updatePopup: false,
      popupActivo2:false,
      addPopup: false,
      deleteDialog : false,
      deleteId : '',
      addPopupData : {},
      editRowData : {},
      popupData : {},
      fields: [
        {
          name: 'no',
          title: '<span class="orange glyphicon glyphicon-user"></span> 번호',
          sortField: 'no',
          dataClass: 'align-middle',
          width: '5%'
        },
        {
          name: 'level',
          title: '등급',
          sortField: 'level',
          width: '7%',
          dataClass: 'align-middle',
          formatter: (value) => {
            console.log("ta!!!!!!!!!!!!!bels ", value)
            return value === 0 ? '관리자' : '일반'
          }
        },
        {
          name: 'id',
          title: '<span class="orange glyphicon glyphicon-user"></span> 아이디',
          sortField: 'id',
          width: '12%',
          dataClass: 'align-middle',
        },
        {
          name: 'name',
          title: '이름',
          sortField: 'name',
          width: '9%',
          dataClass: 'align-middle',
        },
        {
          name: 'email',
          title: '이메일',
          sortField: 'email',
          width: '15%',
          dataClass: 'align-middle',
        },
        {
          name: 'mod_dt',
          title: '수정일',
          sortField: 'mod_dt',
          width: '15%',
          dataClass: 'align-middle',
        },
        {
          name: 'reg_dt',
          title: '등록일',
          sortField: 'reg_dt',
          width: '15%',
          dataClass: 'align-middle',
        },
        {
          name: 'last_login_dt',
          title: '최근 접속일',
          sortField: 'last_login_dt',
          width: '15%',
          dataClass: 'align-middle',
        },
        {
          name: 'login_cnt',
          title: '접속 횟수',
          sortField: 'login_cnt',
          width: '8%',
          dataClass: 'align-middle',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          width: '12%',
          dataClass: 'align-middle',
        }

      ],
      sortOrder: [
        {field: 'species', direction: 'asc'}
      ],
      css: {
        table: {
          tableClass: 'table table-striped table-bordered table-hovered',
          loadingClass: 'loading',
          ascendingIcon: 'glyphicon glyphicon-chevron-up',
          descendingIcon: 'glyphicon glyphicon-chevron-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger',
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination pull-right',
          activeClass: 'btn-primary',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: '',
          },
        }
      }
    }
  },
  computed:{
    /*httpOptions(){
      return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
    },*/
  },
  methods: {

    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    editRow (data) {
      if(data.rowData.level === 0){
        this.notify("주의", `${data.rowData.id} 은 수정 할 수 없습니다. `, "danger");
        return;
      }
      this.editRowData = data.rowData;
      this.popupData = {pw : '', ...this.editRowData}; //성능을 위해 객체 복사 (양방향 바인딩 끊기)
      //this.popupActivo2 = true; // 팝업 오픈
      this.updatePopup = true;
      console.log(this.updatePopup)
    },
    deleteRow (data) {
      this.deleteId = data.rowData.id || '';
      this.deleteDialog = true;
      return;
    },
    async deleteAgree () {
      this.$vs.loading();
      await axios.delete(`/v1/member/${this.deleteId}`, this.popupData).then(res => {
        console.log(res)
        if(res.data.code === 200){
          this.$refs.vuetable.reload();
          this.notify("성공", "삭제 되었습니다.", "success");
          this.deleteId = '';
        }else {
          this.notify("요청 실패", `${res.data.code} Error`, 'danger');
        }

        this.deleteDialog = false;

      }).catch( (e) => {
        this.notify("처리 실패", `관리자에게 문의 하세요.`, "danger");
        this.deleteDialog = false;
        console.error(e);
      })
    },
    notify(title, msg, color="danger"){
      this.$vs.notify({
        title: title,
        text: msg,
        color: color
      })
      this.$vs.loading.close()
    },
    insert() {
      console.log(this.addPopupData)
      if(this.addPopupData.pw.length <= 0){
        this.notify("비밀번호는", "필수 값 입니다.");
        return false;
      }

      if(this.addPopupData.name.length <= 0){
        this.notify("이름은", "필수 값 입니다.");
        return false;
      }

      if(this.addPopupData.id.length <= 0){
        this.notify("아이디", "필수 값 입니다.");
        return false;
      }

      let regType = /^[A-Za-z0-9+]*$/;
      if(!regType.test(this.addPopupData.id)){
        this.notify("아이디", "영문(대소문자), 숫자만 허용 합니다.");
        return false;
      }

      this.$vs.loading();

      axios.post(`/v1/member/add`, this.addPopupData).then(res => {
        console.log(res)
        if(res.data.code === 200){
          this.$refs.vuetable.reload();
          this.$vs.loading.close()
          this.notify("성공", "처리 완료", "success");
          this.addPopup = false;
          this.clear();
          return ;
        }else if(res.data.code === 2000){
          this.notify("아이디 중복", `다른 아이디로 추가 해주세요.`, 'danger');
        }else {
          this.notify("요청 실패", `${res.data.code} Error`, 'danger');
        }

      }).catch( e => {
        this.notify("처리 실패", `관리자에게 문의 하세요.`, "danger");
        console.error(e);
      })
    },
    //TODO 코드 정리 필요
    update () {
      console.log(this.popupData)
      if(this.popupData.pw.length <= 0){
        this.notify("비밀번호는", "필수 값 입니다.");
        return false;
      }

      this.$vs.loading();

      axios.put(`/v1/member`, this.popupData).then(res => {
        console.log(res)
        if(res.data.code === 200){
          this.$vs.loading.close()
          this.notify("성공", "처리 완료", "success");
          this.updatePopup=false;

          //Bind 데이터 변경
          Object.keys(this.popupData).reduce( (acc, key)=>{
            this.editRowData[key] = this.popupData[key];
          }, {});
          return ;
        }
        this.notify("요청 실패", `${res.data.code} Error`, 'danger');

      }).catch(e => {
        this.notify("처리 실패", `관리자에게 문의 하세요.`, "danger");
        console.error(e);
      })

    },
    onLoading () {
      console.log('loading... show your spinner here')
    },
    onLoaded () {
      this.$refs.vuetable.toggleDetailRow(1)
      console.log('loaded! .. hide your spinner here')
    },
    onCellClicked (data) {
      //console.log('cellClicked: ', field.name, data)
      this.$refs.vuetable.toggleDetailRow(data.data.id)
    },
    add()  {
      this.addPopup = true;
    },
    clear() {
      this.addPopupData = { name :'', pw :'', id :'', email:'' }
    },
  },
  created () {
    this.clear();
    if(this.$store.state.user.level !== 0){
      this.$router.push({ path : "/home" });
    }
  }
}
</script>


<style >
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

table.vuetable{
  width: 1200px;
  table-layout: fixed;
  vertical-align: middle !important;
  font-size: 15px !important;
  color : black;
}

.vs-popup--title{
  width: 100%;
  color : white;
  font-weight: 900;
  background-color: orange;
  position: relative;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}

.vs-popup--title h3 {
  transition: all .23s ease .1s;
  font-weight: 900;
  padding: 8px;
  padding-left: 12px;
  padding-top: 12px;
  font-size: 1.2rem;
}

.vs-con-textarea h4 {
  font-size: 0.9rem;
  color : gray;
  padding: 8px;
  padding-bottom: 0;
  cursor: default;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
  border-radius: 5px 5px 0 0;
}

.vs-placeholder-label {
  font-size: 15px !important;
  font-weight: 700;
}

.orange.glyphicon {
  color: orange;
}

.vs-input-margin {
  margin-bottom: 30px;

}

th.sortable {
  color: #000000;
}

</style>